import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

// Components
import JobAd from './JobAd.js';

// Material UI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Chip from '@material-ui/core/Chip';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';

import {getAds, getLocalStorage} from '../api';


class JobCategoryFull extends Component {
  state = {
    ads: [],
    loading: true,
    cities: [],
    filteredCities: [],
    activePage: 1
  }

  async componentDidMount() {
    this.setState({loading: true});
    const id = this.props.match.params.id;
    if (getLocalStorage('kodare_nu_categories').includes(id)) {
      const ads = await getAds(id, this.props.location.state.no);

      const cities = [];
      ads.forEach((ad) => {
        const city = ad.kommunnamn;
        if (!cities.includes(city)) {
          cities.push(city);
        }
      });

      cities.sort();

      this.setState({ads, loading: false, cities});
    }
  }

  handleClick = (e) => {
    const city = e.target.textContent;
    let filteredCities = [];
    if (this.state.filteredCities.includes(city)) {
      filteredCities = this.state.filteredCities.filter((c) => c !== city );
    } else {
      filteredCities = [...this.state.filteredCities, city];
    }

    this.setState({filteredCities});
  }

  removeFilters = () => {
    this.setState({filteredCities: []});
  }

  render() {
    return (
      <Card className="card__full">
      <Helmet>
        <title>{this.props.location.state.name}</title>
      </Helmet>
        { this.state.loading && <LinearProgress /> }
          <CardContent>
          {
            this.state.ads.length > 0 ?
            <React.Fragment>
            <Typography gutterBottom variant="h6" className="category__headline">
              {this.props.location.state.name}
            </Typography>
            <Typography gutterBottom variant="caption" className="category__subtext">
              - {this.props.location.state.no} annonser
            </Typography>
            <div className="cities">
            {
              this.state.cities.map((city) => {
                return (<Chip
                  className="cities__chip"
                  variant={this.state.filteredCities.includes(city) ? 'default' : 'outlined'}
                  key={city}
                  label={city}
                  onClick={this.handleClick}
                />)
              })
            }
            <Chip
              icon={<DeleteIcon />}
              className="cities__chip"
              variant="default"
              label="Rensa filter"
              onClick={this.removeFilters}
            />
            </div>
            {
              this.state.ads.filter((ad) => {
                return this.state.filteredCities.length === 0 ?
                  true : this.state.filteredCities.includes(ad.kommunnamn);
              }).map((ad) => {
                return (
                  <JobAd key={ad.annonsid} {...ad}/>
                )
              })
            }
            </React.Fragment>
            :
            <React.Fragment>
            </React.Fragment>
          }
          </CardContent>
      </Card>
    );
  }
}

export default JobCategoryFull;
