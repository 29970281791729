import React, { Component } from 'react';
import nl2br from 'react-nl2br';
import moment from 'moment';

// Components
import JobButtons from './JobButtons.js';

// Material UI
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

import AlarmIcon from '@material-ui/icons/AlarmOutlined';

import {getAdDetails} from '../api';
import {httpToHttps} from '../helpers';

class JobAd extends Component {
  state = {
    details: {},
    loaded: false
  }

  onChange = async () => {
    if (!this.state.loaded) {
      const details = await getAdDetails(this.props.annonsid);
      this.setState({details, loaded: true});
    }
  }

  getApplyUrl () {
    return this.state.details.ansokan.webbplats ?
      this.state.details.ansokan.webbplats : this.state.details.annons.platsannonsUrl;
  }

  formatDate (d) {
    if (!d) {
      return false;
    }
    return d.split('T')[0];
  }

  checkDate (d) {
    const formattedDate = this.formatDate(d);

    if (!formattedDate) {
      return false;
    }

    return  moment(formattedDate).isBetween(moment.now(), moment().add(1, 'w')) ||
      moment(formattedDate).format('YYYY-MM-DD').toString() === moment().format('YYYY-MM-DD').toString();
  }

  render() {
    return (
      <ExpansionPanel onChange={this.onChange}>
        <ExpansionPanelSummary expandIcon={this.checkDate(this.props.sista_ansokningsdag) ? <AlarmIcon /> : <ExpandMoreIcon />}>
        <Typography>{this.props.annonsrubrik} - <b>{this.props.kommunnamn}</b></Typography>
        </ExpansionPanelSummary>
        {
          this.state.details.annons &&
          <ExpansionPanelDetails className="ad__details">
            {
              this.state.details.arbetsplats.logotypurl &&
              <img src={httpToHttps(this.state.details.arbetsplats.logotypurl)} alt={this.state.details.arbetsplats.arbetsplatsnamn} />
            }
            <p>{this.state.details.arbetsplats.arbetsplatsnamn}</p>
            <p>{this.state.details.arbetsplats.besoksadress}</p>
            <Divider />
            <Typography className="ad__text">
              {nl2br(this.state.details.annons.annonstext)}
            </Typography>
            <Divider />
            <ul>
          <li>Sista ansökningsdag: {this.formatDate(this.state.details.ansokan.sista_ansokningsdag)} {this.checkDate(this.props.sista_ansokningsdag) && <AlarmIcon />}</li>
              <li>{this.state.details.villkor.arbetstid}</li>
              <li>{this.state.details.villkor.lonetyp}</li>
              <li>{this.state.details.villkor.varaktighet}</li>
            </ul>
            <JobButtons
              id={this.props.annonsid}
              url={this.getApplyUrl()}
              address={this.state.details.arbetsplats.besoksadress}
            />
          </ExpansionPanelDetails>
        }
      </ExpansionPanel>
    );
  }
}

export default JobAd;
