import {currentWebsite} from '../helpers';

const getCategories = async () => {
  const categories = currentWebsite().categories;
  const cats = [];

  await Promise.all(categories.map(async (c) => {
    const category = await (await fetch(`https://api.arbetsformedlingen.se/af/v0/platsannonser/soklista/yrken?yrkesgruppid=${c}`)).json();

    category.soklista.sokdata.forEach((cat) => {
      cats.push(cat);
    });
  }));

  updateLocalStorage(cats.map((c) => c.id), 'kodare_nu_categories');

  return cats;
};

const getAds = async (id, antal) => {
  const rows = antal > 5 ? `&antalrader=${antal}` : `&antalrader=5`;
  const ads = await (await fetch(`https://api.arbetsformedlingen.se/af/v0/platsannonser/matchning?yrkesid=${id}${rows}`)).json();

  let relevantAds = [];
  if (ads.matchningslista.matchningdata) {
    relevantAds = ads.matchningslista.matchningdata.filter((ad) => ad.relevans > 75);
  }

  return relevantAds;
};

const getAdDetails = async (id) => {
  const details = await (await fetch(`https://api.arbetsformedlingen.se/af/v0/platsannonser/${id}`)).json();

  return details.platsannons;
};

const LOCALSTORAGE_ID = 'kodare_nu_ids';
const getLocalStorage = (id = LOCALSTORAGE_ID) => {
  return JSON.parse(localStorage.getItem(id));
};

const addLocalStorage = (id) => {
  const adIds = getLocalStorage();

  if (adIds === null) {
    updateLocalStorage([id]);
  } else {
    updateLocalStorage([...adIds, id]);
  }
};

const removeLocalStorage = (adid) => {
  let adIds = getLocalStorage();
  adIds = adIds.filter((id) => id !== adid);

  updateLocalStorage(adIds);
};

const updateLocalStorage = (arr, id = LOCALSTORAGE_ID) => {
  localStorage.setItem(id, JSON.stringify(arr));
}

const clearLocalStorage = () => {
  localStorage.removeItem(LOCALSTORAGE_ID);
};

export {
  getCategories,
  getAds,
  getAdDetails,
  getLocalStorage,
  addLocalStorage,
  removeLocalStorage,
  clearLocalStorage
};
