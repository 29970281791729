import React, { Component } from 'react';
import { Link } from "react-router-dom";

// Components
import JobAd from './JobAd.js';

// Material UI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import {getAds} from '../api';

class JobCategory extends Component {
  state = {
    ads: [],
    loading: true
  }

  async componentDidMount() {
    this.setState({loading: true});
    const ads = await getAds(this.props.id);
    this.setState({ads, loading: false});
  }

  render() {
    if (this.props.antal_platsannonser > 0) {
      return (
        <Card className="card">
          { this.state.loading && <LinearProgress /> }
          <CardContent>
            <Typography gutterBottom variant="h6" className="category__headline">
              <Link
                to={{
                  pathname: `/k/${this.props.id}`,
                  state: {name: this.props.namn, no: this.props.antal_platsannonser}}}
              >
                {this.props.namn}
              </Link>
            </Typography>
            <Typography gutterBottom variant="caption" className="category__subtext">
              - {this.props.antal_platsannonser} annonser
            </Typography>
            {
              this.state.ads.map((ad) => {
                return (
                  <JobAd key={ad.annonsid} {...ad}/>
                )
              })
            }
          </CardContent>
        </Card>
      );
    }

    return (<React.Fragment></React.Fragment>);
  }
}

export default JobCategory;
