import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

// Components
import JobCategory from './JobCategory.js';

import {getCategories} from '../api';
import {currentWebsite} from '../helpers';

class JobIndex extends Component {
  state = {
    categories: []
  }

  async componentDidMount() {
    const categories = await getCategories();
    this.setState({categories})
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{`Din portal för ${currentWebsite().subtitle}relaterade jobb`}</title>
        </Helmet>
        {
          this.state.categories.map((category) => {
            return (
              <JobCategory key={category.id} {...category} />
            )
          })
        }
      </React.Fragment>
    );
  }
}

export default JobIndex;
