import React, { Component } from 'react';

// Material UI
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

// Icons
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationIcon from '@material-ui/icons/LocationOn';
import LinkIcon from '@material-ui/icons/Link';

import {getLocalStorage, addLocalStorage, removeLocalStorage} from '../api';

class JobButtons extends Component {
  state = {
    isFavourite: false
  }

  componentDidMount () {
    const adIds = getLocalStorage();

    if (adIds !== null) {
      this.setState({isFavourite: adIds.includes(this.props.id)});
    }
  }

  onClick = () => {
    if (this.state.isFavourite) {
      removeLocalStorage(this.props.id);
    } else {
      addLocalStorage(this.props.id);
    }

    const event = new Event('favouritesUpdated');
    document.dispatchEvent(event);

    this.setState((prevState) => ({isFavourite: !prevState.isFavourite}));
  }

  render() {
    return (
      <BottomNavigation
        showLabels
        value={this.state.isFavourite ? 0 : -1}
      >
        <BottomNavigationAction
          label={this.state.isFavourite ? 'Sparad' : 'Spara'}
          icon={<FavoriteIcon />}
          onClick={this.onClick}
        />
        <BottomNavigationAction
          label="Ansök"
          icon={<LinkIcon />}
          href={this.props.url}
          target="_blank"
        />
        <BottomNavigationAction
          label="Karta"
          icon={<LocationIcon />}
          href={`https://maps.google.com/?q=${this.props.address}`} target="_blank"
        />
      </BottomNavigation>
    );
  }
}

export default JobButtons;
