import React, { Component } from 'react';
import { Route, Link } from "react-router-dom";
import {Helmet} from 'react-helmet';
import './App.css';

// Components
import JobCategoryFull from './components/JobCategoryFull.js';
import JobIndex from './components/JobIndex.js';

// Material UI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

// Icons
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import FavoriteIcon from '@material-ui/icons/Favorite';

import {getAdDetails, getLocalStorage, clearLocalStorage} from './api';
import {currentWebsite} from './helpers';

const styles = () => ({
  badge: {
    top: 5,
    right: 6,
    'background-color': 'white',
    color: '#f50057',
    width: '13px',
    height: '13px'
  },
});

class App extends Component {
  state = {
    open: false,
    ads: []
  }

  toggleDrawer = (open) => () => {
    this.setState({open});
  };

  componentDidMount () {
    this.getAds();

    document.addEventListener('favouritesUpdated', this.getAds.bind(this));
  }

  async getAds () {
    const adIds = getLocalStorage();

    const ads = await Promise.all([...adIds || []].map(async (id) => {
      return await getAdDetails(id);
    }));

    this.setState({
      ads: ads.map((ad) => {
        return (
          <ListItem button component="a" key={ad.annons.annonsid} href={ad.annons.platsannonsUrl}
          target="_blank">
            <ListItemIcon><LinkIcon /></ListItemIcon>
            <ListItemText
              primary={ad.annons.annonsrubrik}
            />
          </ListItem>
        );
      })
    })
  }

  clearFavorites = () => {
    clearLocalStorage();
    this.setState({ads: []});
  }

  componentWillUnmount () {
    document.removeEventListener('favouritesUpdated', this.getAds.bind(this));
  }

  render() {
    const {title, subtitle} = currentWebsite();

    return (
      <React.Fragment>
        <Helmet
          titleTemplate={`${title} - %s`}
        >
        </Helmet>
        <AppBar color="secondary">
          <Toolbar>
            <IconButton color="inherit" aria-label="Menu" onClick={this.toggleDrawer(true)}>
              <Badge badgeContent={this.state.ads.length} classes={{ badge: this.props.classes.badge }}>
                <FavoriteIcon />
              </Badge>
            </IconButton>
            <Link to="/"><span className="logo">{`${title} - Din portal för ${subtitle}relaterade jobb`}</span></Link>
          </Toolbar>
        </AppBar>
        <Drawer open={this.state.open} onClose={this.toggleDrawer(false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            <List>
              <ListItem>
                <ListItemIcon><FavoriteIcon /></ListItemIcon>
                <ListItemText
                  primary="Sparade annonser"
                />
              </ListItem>
              {this.state.ads}
              <ListItem button onClick={this.clearFavorites}>
                <ListItemIcon><DeleteIcon /></ListItemIcon>
                <ListItemText
                  primary="Rensa"
                />
              </ListItem>
            </List>
          </div>
        </Drawer>
        <div className="paper">
          <Route path="/" exact component={JobIndex} />
          <Route path="/k/:id(\d+)" component={JobCategoryFull} />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(App);
