const degreesToRadians = (degrees) => degrees * Math.PI / 180;

const distanceBetweenCoordinates = (lat1, lon1, lat2, lon2, maxDistance) => {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(lat2-lat1);
  const dLon = degreesToRadians(lon2-lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  const distance = earthRadiusKm * c;

  return maxDistance ? distance < maxDistance : distance;
};

const httpToHttps = (url) => {
  let u = new URL(url);
  u.protocol = 'https:';

  return u;
}

const currentWebsite = () => {
  const {hostname} = window.location;

  if (hostname.includes('kodare') || hostname.includes('localhost')) {
    return {
      categories: [2512],
      title: 'Kodare.nu',
      subtitle: 'kodnings'
    };
  }

  return {
    categories: [3313, 2411],
    title: 'Ekonomer.nu',
    subtitle: 'ekonomi'
  };
}

export {
  distanceBetweenCoordinates,
  httpToHttps,
  currentWebsite
};
